<template>
  <div
    v-loading="filterLoading"
    :element-loading-text="loadingText"
    class="custom-dashboard-table"
  >
    <!-- <h3>Custom filters</h3> -->
    <el-scrollbar>
      <div v-if="allDashboardstats" class="stats-container mb-1 mt-1">
        <el-popover
          v-if="allDashboardstats.includeGlobalDateFilter"
          placement="top-start"
          title="Filter applied on:"
          width="200"
          trigger="hover"
        >
          <div slot="reference">
            <el-select
              v-if="allDashboardstats.includeGlobalDateFilter"
              placeholder="Pick date range"
              v-model="dateFilter.selected_date_picker"
              :size="'mini'"
              @change="setDatePickerDate"
              clearable
            >
              <el-option
                v-for="(el, index) in pickerOptions"
                :key="el.value + '_' + index"
                :value="el.value"
                :label="el.label"
                >{{ el.label }}
              </el-option>
            </el-select>
          </div>
          <div>
            <ul v-for="(op, i) in getStatsLabel" :key="i + '_op'">
              {{
                i + 1
              }}.{{
                op.component_name
              }}
            </ul>
          </div>
        </el-popover>
        <el-date-picker
          v-if="dateFilter.selected_date_picker == 'custom_date'"
          v-model="dateFilter.custom_date"
          size="mini"
          placeholder="Select custom data"
          @change="applyCustomDateFilter"
          format="MM-dd-yyyy"
        >
        </el-date-picker>
        <el-date-picker
          v-if="dateFilter.selected_date_picker == 'custom_date_range'"
          v-model="dateFilter.custom_date_range"
          size="mini"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          type="daterange"
          @change="applyCustomDateRangeFilter"
          format="MM-dd-yyyy"
        >
        </el-date-picker>
        <!-- <el-select
          v-if="dateFilter.selected_date_picker == 'month'"
          v-model="dateFilter.selected_month"
          placeholder="Select month"
          size="mini"
          @change="applyMonthFilter"
    >
      <el-option
        v-for="month in months"
        :key="month.value"
        :value="month.value"
        :label="month.label"
      >{{ month.label }}</el-option>
    </el-select> -->
        <div
          class="filter-card"
          style="min-with: 150px"
          v-for="(filter, index) in allDashboardstats.allCustomFilters"
          :key="
            filter.entity_id +
            'dashboard_filter' +
            (filter.keyValue ? filter.keyValue : 'key') +
            index
          "
        >
          <CustomEntityFilter
            v-if="filter.filter_field_type === 'ENTITY'"
            :field="filter"
            :fieldPlaceholder="getFilterPlaceholder(filter.filter_field)"
            :size="'mini'"
            :isMultiple="true"
            :filters="
              filter.is_relational_data ? applyRelationalFilter([]) : []
            "
            @entityFilterUpdated="entityFilterUpdated"
          >
          </CustomEntityFilter>
          <el-select
            v-else-if="
              filter.filter_field_type !== 'DATE' && filter.filter_field
            "
            :placeholder="getFilterPlaceholder(filter.filter_field)"
            v-model="filter.selected_filter"
            @change="applyCustomFilter(filter, filter.selected_filter)"
            clearable
            size="mini"
            multiple
            collapse-tags
          >
            <el-option
              v-for="(op, index) in getFilterOptions(filter.filter_field)"
              :key="index + '_' + op"
              :value="op"
              :label="op"
            >
              {{ op }}
            </el-option>
          </el-select>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
// import { mapGetters } from "vuex";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import moment from "moment";
import { bus } from "../../main";
export default {
  mixins: [CustomDashboardConfig],
  props: {
    allDashboardstats: Object,
    getAllCompanyTemplatesData: Object
  },
  computed: {
    // ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    getStatsLabel() {
      if (this.allDashboardstats?.allStatComponents) {
        return this.allDashboardstats.allStatComponents.filter(
          (e) => e.date_filter_field
        );
      }
      return [];
    },
  },
  components: {
    // CustomDatePicker: () => import("@/components/widgets/customDatePicker.vue"),
    CustomEntityFilter: () => import("./customDashboardEntityFilter.vue"),
  },
  async mounted() {
    this.setAllFieldsOptions();
  },
  methods: {
    applyCustomDateFilter(value) {
      if (value) {
        // let dateValue = this.getDateByDays(0, value);
        let dateValue = moment(value).utc();
        let filter = {
          data_type: "DATE",
          field: "",
          operator: "real_time",
          selected_date_picker: "custom_date",
          today: "",
          value_field: "",
          value_source: "",
          query_type: "AND",
          validations: {
            data_type: "",
          },
          value_type: "VALUE",
          every_year: "",
          entity_id: "",
          value: dateValue,
        };
        bus.$emit("apply-date-filter", {
          filter: this.mapDateFilters([{ ...filter, ...{ value: dateValue } }]),
        });
      } else {
        bus.$emit("apply-date-filter", {
          reset: true,
        });
      }
    },
    applyCustomDateRangeFilter(value) {
      if (value.length) {
        // let dateValue = this.getDateByDays(0, value[0]);
        // let dateValue2 = this.getDateByDays(0, value[1]);
        let filter = {
          data_type: "DATE",
          field: "",
          operator: "real_time",
          selected_date_picker: "custom_date_range",
          today: "",
          value_field: "",
          value_source: "",
          query_type: "AND",
          validations: {
            data_type: "",
          },
          value_type: "VALUE",
          every_year: "",
          entity_id: "",
          value: value,
        };
        bus.$emit("apply-date-filter", {
          filter: this.mapDateFilters([filter]),
        });
      } else {
        bus.$emit("apply-date-filter", {
          reset: true,
        });
      }
    },
    // applyMonthFilter(value) {
    //   console.log("Month selected:", value);
    // },
    setDatePickerDate(value) {
      if (value) {
        if (value == "custom_date" || value == "custom_date_range") {
          return;
        }
        let data = this.getDatePickerHelper(value);
        let filter = {
          data_type: "DATE",
          field: "",
          operator: "real_time",
          selected_date_picker: value,
          value: data.value,
          today: data.today,
          value_field: "",
          value_source: "",
          query_type: "AND",
          validations: {
            data_type: "",
          },
          value_type: "VALUE",
          every_year: "",
          entity_id: "",
        };
        bus.$emit("apply-date-filter", {
          filter: this.mapDateFilters([filter]),
        });
      } else {
        bus.$emit("apply-date-filter", {
          reset: true,
        });
      }
    },
    entityFilterUpdated(data) {
      if (data && data.entity_id) {
        if (data.value && data.value.length) {
          const selectedEntities =
            this.allDashboardstats.allCustomFilters.filter(
              (e) => e.entity_id == data.entity_id
            );
          if (
            selectedEntities &&
            selectedEntities.length &&
            data.value &&
            data.filter
          ) {
            let filters = [
              {
                data_type: "ENTITY",
                field: data.filter.filter_field,
                operator: "in",
                value: data.value,
                value_field: "",
                value_source: "",
                query_type: "AND",
                validations: {
                  data_type: "",
                },
                value_type: "VALUE",
                entity_id: data.entity_id,
              },
            ];
            bus.$emit("apply-custom-filter", {
              fields: this.mapDateFilters(filters),
            });
          } else {
            bus.$emit("apply-custom-filter", {
              reset: true,
              entity_id: data.entity_id,
            });
          }
        } else {
          bus.$emit("apply-custom-filter", {
            reset: true,
            entity_id: data.entity_id,
            field_id: data.filter.filter_field,
          });
        }
      } else {
        bus.$emit("apply-custom-filter", {
          reset: true,
        });
      }
    },
    dateFilterUpdated(data) {
      if (data && data.entity_id) {
        const selectedEntities = this.allDashboardstats.allCustomFilters.filter(
          (e) => e.entity_id == data.entity_id
        );
        if (
          selectedEntities &&
          selectedEntities.length &&
          data.operator &&
          data.filter &&
          (data.value || data.today)
        ) {
          let filters = [
            {
              data_type: "DATE",
              field: data.filter.filter_field,
              operator: "real_time",
              selected_date_picker: data.operator,
              value: data.value,
              today: data.today,
              value_field: "",
              value_source: "",
              query_type: "AND",
              validations: {
                data_type: "",
              },
              value_type: "VALUE",
              entity_id: data.entity_id,
            },
          ];
          bus.$emit("apply-custom-filter", {
            fields: this.mapDateFilters(filters),
          });
        } else {
          bus.$emit("apply-custom-filter", {
            reset: true,
            entity_id: data.entity_id,
          });
        }
      }
    },
    getFilterPlaceholder(key) {
      let data = this.getFilterFieldOptions(key);
      return `By ${data && data.label ? data.label : "field"}`;
    },
    getFilterOptions(key) {
      let data = this.getFilterFieldOptions(key);
      return data && data.options ? data.options : [];
    },
    applyCustomFilter(key, value) {
      if (key && key.entity_id) {
        if (value && value.length > 0) {
          const selectedEntities =
            this.allDashboardstats.allCustomFilters.filter(
              (e) => e.entity_id == key.entity_id && e.selected_filter
            );
          if (selectedEntities && selectedEntities.length) {
            let filters = [];
            selectedEntities.forEach((el) => {
              if (el.selected_filter && el.selected_filter.length) {
                filters.push({
                  field: el.filter_field,
                  operator: "in",
                  value:
                    typeof el.selected_filter == "object"
                      ? el.selected_filter
                      : [el.selected_filter],
                  value_type: "",
                  value_source: "",
                  data_type: "STRING",
                  query_type: "AND",
                  value_field: "",
                  validations: {
                    data_type: "",
                  },
                  data_source: "VALUE",
                  entity_id: el.entity_id,
                });
              }
            });
            if (filters && filters.length) {
              bus.$emit("apply-custom-filter", {
                fields: this.mapDateFilters(filters),
              });
            } else {
              bus.$emit("apply-custom-filter", {
                reset: true,
              });
            }
          } else {
            bus.$emit("apply-custom-filter", {
              reset: true,
              entity_id: key.entity_id,
            });
          }
        } else {
          bus.$emit("apply-custom-filter", {
            reset: true,
            entity_id: key.entity_id,
            field_id: key.filter_field,
          });
        }
      } else {
        bus.$emit("apply-custom-filter", {
          reset: true,
        });
      }
      // if(key){
      //   bus.$emit("apply-custom-filter", {
      //     field: key
      //   });
      // }else{
      //   bus.$emit("apply-custom-filter", {
      //     reset: true
      //   });
      // }
    },
    getFilterFieldOptions(key) {
      if (key) {
        return this.allTemplateSelectFields.find((e) => e.key == key);
      }
      return {
        label: "field",
        options: [],
      };
    },
    setAllFieldsOptions() {
      this.filterLoading = true;
      if (
        this.getAllCompanyTemplatesData &&
        this.getAllCompanyTemplatesData.data
      ) {
        let allTemplateFields = [];
        this.getAllCompanyTemplatesData.data.forEach((element) => {
          let data = JSON.parse(JSON.stringify(element));
          allTemplateFields = [
            ...allTemplateFields,
            ...this.getTemplateFieldsByType(data, [
              "SELECT",
              "DATE",
              "ENTITY",
              "MULTI_SELECT",
            ]),
          ];
          allTemplateFields.forEach((field) => {
            this.allTemplateSelectFields.push({
              label: field.label,
              options: field.options || [],
              key: field.key,
            });
            // this.allTemplateSelectFields[field.key] = {label: field.label, options: field.options || []};
          });
        });
      }
      this.filterLoading = false;
    },
  },
  data() {
    return {
      filterLoading: false,
      loadingText: "Getting custom filters",
      customFilters: [],
      allTemplateSelectFields: [],
      dateFilter: {
        filter_field: "",
        selected_date_picker: "",
        entity_id: "",
        custom_date: "",
        custom_date_range: [],
        selected_month: "",
      },
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "Next year",
          value: "next_year",
        },
        {
          label: "Custom date",
          value: "custom_date",
        },
        {
          label: "Custom date range",
          value: "custom_date_range",
        },
        // { label: "Month", value: "month" },
      ],
      months: [
        { label: "January", value: "01" },
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" }
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.stats-container {
  // margin-bottom: 1.25em;
  display: flex;
  gap: 10px;
}
</style>
